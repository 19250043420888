import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api } from "../Assets/Url/Url";

function Art() {
  const { id } = useParams();
  const [artwork, setArtwork] = useState({});
  const [loading, setLoading] = useState(true);
  const [arrange, setArrange] = useState();

  useEffect(() => {
    const fetchArtworkById = async () => {
      try {
        const response = await axios.get(`${api}/api/artwork/${id}`);

        const fetchedArtwork = response.data;

        const orderAmount =
          fetchedArtwork.price * (1 - fetchedArtwork.discount / 100);

        setArtwork(fetchedArtwork);
        setLoading(false);

        setOrderData({
          order_amount: orderAmount,
          transaction_method: "",
          shipping_address: "",
          city: "",
          state: "",
          country: "",
          postal_code: "",
          order_artworks: [{ artwork: id, quantity: 0 }],
        });
      } catch (error) {
        console.error("Error fetching artwork:", error);
        setLoading(false);
      }
    };

    fetchArtworkById();
  }, [id]);

  const [orderData, setOrderData] = useState({
    order_amount: 0,
    transaction_method: "",
    shipping_address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    order_artworks: [{ artwork: id, quantity: 0 }],
  });

  const handleOrderChange = (e) => {
    const { name, value } = e.target;

    if (name === "order_artwork_quantity") {
      const updatedOrderArtworks = orderData.order_artworks.map((order) => {
        if (order.artwork === id) {
          return { ...order, quantity: parseInt(value) };
        }
        return order;
      });

      const updatedOrderAmount =
        artwork.price *
        (1 - artwork.discount / 100) *
        updatedOrderArtworks.find((order) => order.artwork === id)?.quantity;

      setOrderData({
        ...orderData,
        order_artworks: updatedOrderArtworks,
        order_amount: updatedOrderAmount || 0,
      });
    } else {
      setOrderData({
        ...orderData,
        [name]: value,
      });
    }
  };

  const handleOrderSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        `${api}/api/profile/order/add/`,
        JSON.stringify(orderData),
        { headers }
      );

      setArrange(response.data);

      console.log("Order submitted successfully:", response.data);

      setOrderData({
        order_amount: 0,
        transaction_method: "",
        shipping_address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        order_artworks: [{ artwork: id, quantity: 0 }],
      });
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <img
            src={artwork?.images[0]?.image}
            alt=""
            style={{ width: "100px" }}
          />
          <h1>
            {artwork.name}{" "}
            <span style={{ fontSize: "20px" }}>status: {artwork.status}</span>
          </h1>
          <h1>Price: {artwork.price}</h1>
          <p>{artwork.description}</p>

          <h2>Order Artwork</h2>
          <form onSubmit={handleOrderSubmit}>
            <div>
              <label htmlFor="order_amount">Order Amount</label>
              <input
                type="number"
                id="order_amount"
                name="order_amount"
                value={orderData.order_amount}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="transaction_method">Transaction Method</label>
              <input
                type="text"
                id="transaction_method"
                name="transaction_method"
                value={orderData.transaction_method}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="shipping_address">Shipping Address</label>
              <textarea
                id="shipping_address"
                name="shipping_address"
                value={orderData.shipping_address}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={orderData.city}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={orderData.state}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={orderData.country}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="postal_code">Postal Code</label>
              <input
                type="text"
                id="postal_code"
                name="postal_code"
                value={orderData.postal_code}
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <label htmlFor="order_artwork_quantity">
                Order Artwork Quantity
              </label>
              <input
                type="number"
                id="order_artwork_quantity"
                name="order_artwork_quantity"
                value={
                  orderData.order_artworks.find((order) => order.artwork === id)
                    ?.quantity || 0
                }
                onChange={handleOrderChange}
                required
              />
            </div>
            <div>
              <button type="submit">Submit Order</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Art;
