import React, { useState } from "react";
import { api } from "../Assets/Url/Url";
import logo from "../Assets/Images/benaam-svg-logo-1.svg";
import google from "../Assets/Images/google-logo.svg";
import banner from "../Assets/Images/banner.gif";
import { Link } from "react-router-dom";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  const [responseText, setResponseText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${api}/api/login/`;

    const requestBody = {
      username: username,
      password: password,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const responseData = await response.json();

        localStorage.setItem("token", responseData.token);
        console.log(responseData.message);
        setLoginMessage("Login successful");
        setPassword("");
        setUsername("");
      } else {
        const errorResponse = await response.json();
        setResponseText(errorResponse.message);
        setLoginMessage("Login failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoginMessage("An error occurred");
    }
  };

  return (
    <section className="login-section">
      <div className="container-fluid">
        <div className="row">
          <div className="center">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <img className="logo" src={logo} alt="logo_icon" />
              <h1 className="welcome-heading heavy">Welcome Back</h1>
              <form onSubmit={handleSubmit}>
                <div className="direction-column">
                  <label htmlFor="usernameoremail">
                    Username or Email <span className="secondary"> *</span>
                  </label>
                  <input
                    type="text"
                    id="usernameoremail"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  {responseText && (
                    <p className="username-message">{responseText}</p>
                  )}
                </div>
                <div className="direction-column margin_form">
                  <label htmlFor="password">
                    Password <span className="secondary"> *</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="space-between">
                  <label>
                    <input type="checkbox" className="checkbox" />
                    <span className="custom-checkbox"></span>
                    Remember me
                  </label>
                  <a href="#" className="forget">
                    Forget Password?
                  </a>
                </div>
                <button className="submit-btn" type="submit">
                  <span className="heading-submit">Login</span>
                </button>
              </form>
              <div className="horizontal-line">
                <hr />
                <span className="login-heading">Or login with</span>
                <hr />
              </div>
              <button className="google-btn">
                <img className="google-icon" src={google} alt="google_icon" />
                <span className="sign-heading">Sign in with Google</span>
              </button>
              <h6 className="sign-link">
                Don't have an account?{" "}
                <Link to="/signup" className="primary">
                  Sign up
                </Link>
              </h6>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-7 padding-zero">
              <div className="banner-container">
                <img
                  className="banner-img"
                  src={banner}
                  alt="banner-gif"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginForm;
