import React, { useState, useEffect } from "react";
import { api } from "../Assets/Url/Url";

function Profile() {
  const [profileData, setProfileData] = useState({
    verified: false,
    experience: "",
    bio: "",
    banner_image: null,
  });
  const [artist, setArtist] = useState({
    verified: false,
    experience: "",
    bio: "",
    banner_image: null,
    artwork: [],
  });

  const [editMode, setEditMode] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setProfileData({
        ...profileData,
        [name]: files[0],
      });
    } else {
      setProfileData({
        ...profileData,
        [name]: value,
      });
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();

      Object.entries(profileData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await fetch(`${api}/api/artist-profile/edit/`, {
        method: "PUT",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Response:", responseData);
        setProfileData(responseData);
        setArtist(responseData);

        setEditMode(false);
      } else {
        console.error("HTTP Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleDeleteProfile = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${api}/api/artist-profile/delete/`, {
        method: "DELETE",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        console.log("Profile deleted successfully");
      } else {
        console.error("HTTP Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${api}/api/artist-profile`, {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (response.ok) {
          const responseData = await response.json();

          setProfileData(responseData);
          setArtist(responseData);
        } else {
          console.error("HTTP Error:", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchProfileData();
  }, []);

  return (
    <div>
      <h1>Artist Profile</h1>
      {editMode ? (
        <div>
          <label>
            Verified:
            <input
              type="checkbox"
              name="verified"
              checked={profileData.verified}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label>
            Experience:
            <input
              type="text"
              name="experience"
              value={profileData.experience}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label>
            Bio:
            <textarea
              name="bio"
              value={profileData.bio}
              onChange={handleInputChange}
            />
          </label>
          <br />
          <label>
            Banner Image:
            <input
              type="file"
              name="banner_image"
              onChange={handleInputChange}
            />
          </label>
          <br />
          <button onClick={handleSave}>Save</button>
        </div>
      ) : (
        <div>
          <p>Verified: {artist.verified ? "Yes" : "No"}</p>
          <p>Experience: {artist.experience}</p>
          <p>Bio: {artist.bio}</p>
          {artist.banner_image ? (
            <div>
              <p>Current Banner Image:</p>
              <img
                src={artist.banner_image}
                alt="Banner"
                style={{ maxWidth: "100px" }}
              />
            </div>
          ) : (
            <p>Banner Image: No</p>
          )}
          <br />
          <br />
          <button onClick={handleEditClick}>Edit Profile</button>
          <br />
          <button onClick={handleDeleteProfile}>Delete Profile</button>

          <br />
          <br />
          <h1>Artwork</h1>
          <br />
          {console.log(artist)}
          <div style={{ display: "flex", gap: "60px" }}>
            {artist?.artworks?.map((artwork, index) => {
              return (
                <div key={index}>
                  <h2>{artwork?.name}</h2>
                  <h2>{artwork.description}</h2>
                  <h2>{artwork.discount}</h2>
                  <h2>{artwork.price}</h2>
                  <h2>{artwork.status}</h2>

                  {artwork.images &&
                    artwork.images.map((image, imgIndex) => (
                      <div key={imgIndex}>
                        <img
                          src={image.image}
                          alt={`Artwork Image ${imgIndex}`}
                          style={{ maxWidth: "100px" }}
                        />
                      </div>
                    ))}
                </div>
              );
            })}
          </div>
          <br />
          <br />
        </div>
      )}
    </div>
  );
}

export default Profile;
