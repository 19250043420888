import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../Assets/Url/Url";
import { Link } from "react-router-dom";

function Artwork() {
  const [artworks, setArtworks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArtworks = async () => {
      try {
        const response = await axios.get(`${api}/api/artworks/`);
        setArtworks(response.data.results);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching artworks:", error);
        setLoading(false);
      }
    };

    fetchArtworks();
  }, []);

  return (
    <>
      <h1>List of Artworks</h1>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {artworks.map((artworks, index) => (
          <Link to={`/art/${artworks.id}`} style={{ textDecoration: "none" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "50px",
              }}
              key={index}
            >
              <img
                src={artworks?.images[0]?.image}
                alt=""
                style={{ width: "100px" }}
              />
              <h2>
                {artworks?.name}{" "}
                <span style={{ fontSize: "10px", color: "orangered" }}>
                  {artworks?.status}
                </span>
              </h2>
              <h2>
                {artworks?.price} <span>{artworks?.discount}%</span>
              </h2>
              <p>{artworks?.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default Artwork;
