import React, { useState } from "react";
import { api } from "../Assets/Url/Url";
import logo from "../Assets/Images/benaam-svg-logo-1.svg";
import google from "../Assets/Images/google-logo.svg";
import banner from "../Assets/Images/banner.gif";
import { Link } from "react-router-dom";
function SignUp() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signMessage, setSignMessage] = useState("");
  const [responseEmail, setResponseEmail] = useState("");

  const handleInputChange = () => {
    setSignMessage("");
  };
  const handleInputMail = () => {
    setResponseEmail("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${api}/api/register/`;

    const requestBody = {
      email: email,
      username: username,
      password: password,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        localStorage.setItem("token", responseData.token);
        console.log(responseData.message);
        setSignMessage("Signup successful");
        setPassword("");
        setUsername("");
        setEmail("");
      } else {
        const errorResponse = await response.json();
        if (errorResponse.username !== undefined) {
          setSignMessage(errorResponse.username);
        } else {
          setSignMessage(errorResponse.message);
        }
        setResponseEmail(errorResponse.email);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setSignMessage("An error occurred");
    }
  };

  return (
    <section className="login-section">
      <div className="container-fluid">
        <div className="row">
          <div className="center">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <img className="logo" src={logo} alt="logo_icon" />
              <h1 className="welcome-heading heavy">Welcome at Benaaam!</h1>
              <form onSubmit={handleSubmit}>
                <div className="direction-column margin_form">
                  <label htmlFor="email">
                    Email <span className="secondary"> *</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleInputMail();
                    }}
                    required
                  />
                  {responseEmail && (
                    <p className="username-message">{responseEmail}</p>
                  )}
                </div>
                <div className="direction-column margin_form">
                  <label htmlFor="username">
                    Username <span className="secondary"> *</span>
                  </label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      handleInputChange();
                    }}
                    required
                  />
                  {signMessage && (
                    <p className="username-message">{signMessage}</p>
                  )}
                </div>
                <div className="direction-column margin_form marginBottom-zero">
                  <label htmlFor="password">
                    Password <span className="secondary"> *</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button className="submit-btn margin_twentyfour" type="submit">
                  <span className="heading-submit">Sign Up</span>
                </button>
              </form>

              <button className="google-btn">
                <img className="google-icon" src={google} alt="google_icon" />
                <span className="sign-heading">SignUp with Google</span>
              </button>
              <h6 className="sign-link">
                Already have an account?{" "}
                <Link to="/" className="primary">
                  login
                </Link>
              </h6>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-7 padding-zero">
              <div className="sign-container"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
