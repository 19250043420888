import Integration from "./components/Integration";
import Login from "./components/Login";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import SignUp from "./components/SignUp";

import Update from "./components/Update";
import Edit from "./components/Edit";
import Artwork from "./components/Artwork";
import Art from "./components/Art";
import Order from "./components/Order";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/addartwork" element={<Integration />} />
          <Route path="/profile" element={<Update />} />
          <Route path="/artist" element={<Edit />} />
          <Route path="/artworks" element={<Artwork />} />
          <Route path="/art/:id" element={<Art />} />
          <Route path="/order" element={<Order />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
