import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../Assets/Url/Url";

function Order() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Token ${token}`,
        };

        const response = await axios.get(`${api}/api/artist-profile/orders/`, {
          headers,
        });

        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div>
      <h2>Your Orders</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Series Number</th>
                <th>Status</th>
                <th>Country</th>
                <th>State</th>
                <th>Shipping Address</th>
                <th>Transaction Method</th>
                <th>Order Amount</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={order.id}>
                  <td>{index + 1}</td>
                  <td>{order.status}</td>
                  <td>{order.country}</td>
                  <td>{order.state}</td>
                  <td>{order.shipping_address}</td>
                  <td>{order.transaction_method}</td>
                  <td>{order.order_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default Order;
