import React, { useEffect, useState } from "react";
import { api } from "../Assets/Url/Url";
import { Link } from "react-router-dom";

function MyComponent() {
  const [artworkData, setArtworkData] = useState({
    name: "Haris",
    price: 2280,
    discount: 20,
    description: "Message",
    status: "Available",
    category: "artistic",
    category_name: "art",
    images: [],
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "number") {
      setArtworkData({
        ...artworkData,
        [name]: parseFloat(value),
      });
    } else if (name === "images") {
      const selectedImages = Array.from(e.target.files);
      setArtworkData({ ...artworkData, [name]: selectedImages });
    } else {
      setArtworkData({ ...artworkData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", artworkData.name);
      formData.append("price", artworkData.price);
      formData.append("description", artworkData.description);
      formData.append("status", artworkData.status);
      formData.append("discount", artworkData.discount);
      formData.append("category", artworkData.category);
      formData.append("category_name", artworkData.category_name);

      artworkData.images.forEach((image, index) => {
        formData.append(`images`, image);
      });

      const token = localStorage.getItem("token");

      const headers = {
        Authorization: `Token ${token}`,
      };

      const response = await fetch(`${api}/api/artwork/add/`, {
        method: "POST",
        body: formData,
        headers: headers,
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Response:", responseData);
      } else {
        console.error("HTTP Error:", response.statusText);
      }

      setArtworkData({
        name: "",
        price: "",
        discount: "",
        description: "",
        status: "",
        category: "",
        category_name: "",
        images: [],
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <h2>Add Artwork</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            name="name"
            id="name"
            value={artworkData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="price">Price:</label>
          <input
            type="number"
            name="price"
            id="price"
            value={artworkData.price}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="discount">Discount (%):</label>
          <input
            type="text"
            name="discount"
            id="discount"
            value={artworkData.discount}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <input
            type="text"
            name="description"
            id="description"
            value={artworkData.description}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="status">Status:</label>
          <input
            type="text"
            name="status"
            id="status"
            value={artworkData.status}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="category">Category:</label>
          <input
            type="text"
            name="category"
            id="category"
            value={artworkData.category}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="category_name">Category Name:</label>
          <input
            type="text"
            name="category_name"
            id="category_name"
            value={artworkData.category_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="images">Upload Images:</label>
          <input
            type="file"
            name="images"
            id="images"
            multiple
            onChange={handleChange}
          />
        </div>

        <button type="submit">Submit</button>
        <br />
        <br />
      </form>
    </div>
  );
}

export default MyComponent;
